exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-components-accordion-mdx": () => import("./../../../src/pages/components/accordion.mdx" /* webpackChunkName: "component---src-pages-components-accordion-mdx" */),
  "component---src-pages-components-alerts-mdx": () => import("./../../../src/pages/components/alerts.mdx" /* webpackChunkName: "component---src-pages-components-alerts-mdx" */),
  "component---src-pages-components-anchor-mdx": () => import("./../../../src/pages/components/anchor.mdx" /* webpackChunkName: "component---src-pages-components-anchor-mdx" */),
  "component---src-pages-components-autocomplete-mdx": () => import("./../../../src/pages/components/autocomplete.mdx" /* webpackChunkName: "component---src-pages-components-autocomplete-mdx" */),
  "component---src-pages-components-avatar-mdx": () => import("./../../../src/pages/components/avatar.mdx" /* webpackChunkName: "component---src-pages-components-avatar-mdx" */),
  "component---src-pages-components-breadcrumbs-mdx": () => import("./../../../src/pages/components/breadcrumbs.mdx" /* webpackChunkName: "component---src-pages-components-breadcrumbs-mdx" */),
  "component---src-pages-components-button-group-mdx": () => import("./../../../src/pages/components/button-group.mdx" /* webpackChunkName: "component---src-pages-components-button-group-mdx" */),
  "component---src-pages-components-button-mdx": () => import("./../../../src/pages/components/button.mdx" /* webpackChunkName: "component---src-pages-components-button-mdx" */),
  "component---src-pages-components-checkbox-mdx": () => import("./../../../src/pages/components/checkbox.mdx" /* webpackChunkName: "component---src-pages-components-checkbox-mdx" */),
  "component---src-pages-components-chrome-mdx": () => import("./../../../src/pages/components/chrome.mdx" /* webpackChunkName: "component---src-pages-components-chrome-mdx" */),
  "component---src-pages-components-code-block-mdx": () => import("./../../../src/pages/components/code-block.mdx" /* webpackChunkName: "component---src-pages-components-code-block-mdx" */),
  "component---src-pages-components-code-mdx": () => import("./../../../src/pages/components/code.mdx" /* webpackChunkName: "component---src-pages-components-code-mdx" */),
  "component---src-pages-components-color-picker-mdx": () => import("./../../../src/pages/components/color-picker.mdx" /* webpackChunkName: "component---src-pages-components-color-picker-mdx" */),
  "component---src-pages-components-color-swatch-mdx": () => import("./../../../src/pages/components/color-swatch.mdx" /* webpackChunkName: "component---src-pages-components-color-swatch-mdx" */),
  "component---src-pages-components-combobox-mdx": () => import("./../../../src/pages/components/combobox.mdx" /* webpackChunkName: "component---src-pages-components-combobox-mdx" */),
  "component---src-pages-components-date-picker-mdx": () => import("./../../../src/pages/components/date-picker.mdx" /* webpackChunkName: "component---src-pages-components-date-picker-mdx" */),
  "component---src-pages-components-dots-mdx": () => import("./../../../src/pages/components/dots.mdx" /* webpackChunkName: "component---src-pages-components-dots-mdx" */),
  "component---src-pages-components-draggable-mdx": () => import("./../../../src/pages/components/draggable.mdx" /* webpackChunkName: "component---src-pages-components-draggable-mdx" */),
  "component---src-pages-components-drawer-mdx": () => import("./../../../src/pages/components/drawer.mdx" /* webpackChunkName: "component---src-pages-components-drawer-mdx" */),
  "component---src-pages-components-file-upload-mdx": () => import("./../../../src/pages/components/file-upload.mdx" /* webpackChunkName: "component---src-pages-components-file-upload-mdx" */),
  "component---src-pages-components-global-alert-mdx": () => import("./../../../src/pages/components/global-alert.mdx" /* webpackChunkName: "component---src-pages-components-global-alert-mdx" */),
  "component---src-pages-components-grid-mdx": () => import("./../../../src/pages/components/grid.mdx" /* webpackChunkName: "component---src-pages-components-grid-mdx" */),
  "component---src-pages-components-icon-button-mdx": () => import("./../../../src/pages/components/icon-button.mdx" /* webpackChunkName: "component---src-pages-components-icon-button-mdx" */),
  "component---src-pages-components-index-mdx": () => import("./../../../src/pages/components/index.mdx" /* webpackChunkName: "component---src-pages-components-index-mdx" */),
  "component---src-pages-components-inline-mdx": () => import("./../../../src/pages/components/inline.mdx" /* webpackChunkName: "component---src-pages-components-inline-mdx" */),
  "component---src-pages-components-input-group-mdx": () => import("./../../../src/pages/components/input-group.mdx" /* webpackChunkName: "component---src-pages-components-input-group-mdx" */),
  "component---src-pages-components-input-mdx": () => import("./../../../src/pages/components/input.mdx" /* webpackChunkName: "component---src-pages-components-input-mdx" */),
  "component---src-pages-components-legacy-combobox-mdx": () => import("./../../../src/pages/components/legacy-combobox.mdx" /* webpackChunkName: "component---src-pages-components-legacy-combobox-mdx" */),
  "component---src-pages-components-legacy-menu-mdx": () => import("./../../../src/pages/components/legacy-menu.mdx" /* webpackChunkName: "component---src-pages-components-legacy-menu-mdx" */),
  "component---src-pages-components-legacy-select-mdx": () => import("./../../../src/pages/components/legacy-select.mdx" /* webpackChunkName: "component---src-pages-components-legacy-select-mdx" */),
  "component---src-pages-components-lists-mdx": () => import("./../../../src/pages/components/lists.mdx" /* webpackChunkName: "component---src-pages-components-lists-mdx" */),
  "component---src-pages-components-menu-mdx": () => import("./../../../src/pages/components/menu.mdx" /* webpackChunkName: "component---src-pages-components-menu-mdx" */),
  "component---src-pages-components-modal-mdx": () => import("./../../../src/pages/components/modal.mdx" /* webpackChunkName: "component---src-pages-components-modal-mdx" */),
  "component---src-pages-components-multi-thumb-range-mdx": () => import("./../../../src/pages/components/multi-thumb-range.mdx" /* webpackChunkName: "component---src-pages-components-multi-thumb-range-mdx" */),
  "component---src-pages-components-multiselect-mdx": () => import("./../../../src/pages/components/multiselect.mdx" /* webpackChunkName: "component---src-pages-components-multiselect-mdx" */),
  "component---src-pages-components-notifications-mdx": () => import("./../../../src/pages/components/notifications.mdx" /* webpackChunkName: "component---src-pages-components-notifications-mdx" */),
  "component---src-pages-components-pagination-mdx": () => import("./../../../src/pages/components/pagination.mdx" /* webpackChunkName: "component---src-pages-components-pagination-mdx" */),
  "component---src-pages-components-palette-mdx": () => import("./../../../src/pages/components/palette.mdx" /* webpackChunkName: "component---src-pages-components-palette-mdx" */),
  "component---src-pages-components-pane-mdx": () => import("./../../../src/pages/components/pane.mdx" /* webpackChunkName: "component---src-pages-components-pane-mdx" */),
  "component---src-pages-components-paragraph-mdx": () => import("./../../../src/pages/components/paragraph.mdx" /* webpackChunkName: "component---src-pages-components-paragraph-mdx" */),
  "component---src-pages-components-progress-mdx": () => import("./../../../src/pages/components/progress.mdx" /* webpackChunkName: "component---src-pages-components-progress-mdx" */),
  "component---src-pages-components-radio-mdx": () => import("./../../../src/pages/components/radio.mdx" /* webpackChunkName: "component---src-pages-components-radio-mdx" */),
  "component---src-pages-components-range-mdx": () => import("./../../../src/pages/components/range.mdx" /* webpackChunkName: "component---src-pages-components-range-mdx" */),
  "component---src-pages-components-select-mdx": () => import("./../../../src/pages/components/select.mdx" /* webpackChunkName: "component---src-pages-components-select-mdx" */),
  "component---src-pages-components-sheet-mdx": () => import("./../../../src/pages/components/sheet.mdx" /* webpackChunkName: "component---src-pages-components-sheet-mdx" */),
  "component---src-pages-components-skeleton-mdx": () => import("./../../../src/pages/components/skeleton.mdx" /* webpackChunkName: "component---src-pages-components-skeleton-mdx" */),
  "component---src-pages-components-span-mdx": () => import("./../../../src/pages/components/span.mdx" /* webpackChunkName: "component---src-pages-components-span-mdx" */),
  "component---src-pages-components-spinner-mdx": () => import("./../../../src/pages/components/spinner.mdx" /* webpackChunkName: "component---src-pages-components-spinner-mdx" */),
  "component---src-pages-components-split-button-mdx": () => import("./../../../src/pages/components/split-button.mdx" /* webpackChunkName: "component---src-pages-components-split-button-mdx" */),
  "component---src-pages-components-status-indicator-mdx": () => import("./../../../src/pages/components/status-indicator.mdx" /* webpackChunkName: "component---src-pages-components-status-indicator-mdx" */),
  "component---src-pages-components-stepper-mdx": () => import("./../../../src/pages/components/stepper.mdx" /* webpackChunkName: "component---src-pages-components-stepper-mdx" */),
  "component---src-pages-components-table-mdx": () => import("./../../../src/pages/components/table.mdx" /* webpackChunkName: "component---src-pages-components-table-mdx" */),
  "component---src-pages-components-tabs-mdx": () => import("./../../../src/pages/components/tabs.mdx" /* webpackChunkName: "component---src-pages-components-tabs-mdx" */),
  "component---src-pages-components-tags-mdx": () => import("./../../../src/pages/components/tags.mdx" /* webpackChunkName: "component---src-pages-components-tags-mdx" */),
  "component---src-pages-components-textarea-mdx": () => import("./../../../src/pages/components/textarea.mdx" /* webpackChunkName: "component---src-pages-components-textarea-mdx" */),
  "component---src-pages-components-theme-object-mdx": () => import("./../../../src/pages/components/theme-object.mdx" /* webpackChunkName: "component---src-pages-components-theme-object-mdx" */),
  "component---src-pages-components-theme-provider-mdx": () => import("./../../../src/pages/components/theme-provider.mdx" /* webpackChunkName: "component---src-pages-components-theme-provider-mdx" */),
  "component---src-pages-components-tiles-mdx": () => import("./../../../src/pages/components/tiles.mdx" /* webpackChunkName: "component---src-pages-components-tiles-mdx" */),
  "component---src-pages-components-timeline-mdx": () => import("./../../../src/pages/components/timeline.mdx" /* webpackChunkName: "component---src-pages-components-timeline-mdx" */),
  "component---src-pages-components-toggle-button-mdx": () => import("./../../../src/pages/components/toggle-button.mdx" /* webpackChunkName: "component---src-pages-components-toggle-button-mdx" */),
  "component---src-pages-components-toggle-icon-button-mdx": () => import("./../../../src/pages/components/toggle-icon-button.mdx" /* webpackChunkName: "component---src-pages-components-toggle-icon-button-mdx" */),
  "component---src-pages-components-toggle-mdx": () => import("./../../../src/pages/components/toggle.mdx" /* webpackChunkName: "component---src-pages-components-toggle-mdx" */),
  "component---src-pages-components-tooltip-mdx": () => import("./../../../src/pages/components/tooltip.mdx" /* webpackChunkName: "component---src-pages-components-tooltip-mdx" */),
  "component---src-pages-components-tooltip-modal-mdx": () => import("./../../../src/pages/components/tooltip-modal.mdx" /* webpackChunkName: "component---src-pages-components-tooltip-modal-mdx" */),
  "component---src-pages-components-typography-mdx": () => import("./../../../src/pages/components/typography.mdx" /* webpackChunkName: "component---src-pages-components-typography-mdx" */),
  "component---src-pages-components-utilities-mdx": () => import("./../../../src/pages/components/utilities.mdx" /* webpackChunkName: "component---src-pages-components-utilities-mdx" */),
  "component---src-pages-components-versions-mdx": () => import("./../../../src/pages/components/versions.mdx" /* webpackChunkName: "component---src-pages-components-versions-mdx" */),
  "component---src-pages-components-well-mdx": () => import("./../../../src/pages/components/well.mdx" /* webpackChunkName: "component---src-pages-components-well-mdx" */),
  "component---src-pages-content-actionable-language-mdx": () => import("./../../../src/pages/content/actionable-language.mdx" /* webpackChunkName: "component---src-pages-content-actionable-language-mdx" */),
  "component---src-pages-content-grammar-mdx": () => import("./../../../src/pages/content/grammar.mdx" /* webpackChunkName: "component---src-pages-content-grammar-mdx" */),
  "component---src-pages-content-index-mdx": () => import("./../../../src/pages/content/index.mdx" /* webpackChunkName: "component---src-pages-content-index-mdx" */),
  "component---src-pages-content-principles-mdx": () => import("./../../../src/pages/content/principles.mdx" /* webpackChunkName: "component---src-pages-content-principles-mdx" */),
  "component---src-pages-content-punctuation-mdx": () => import("./../../../src/pages/content/punctuation.mdx" /* webpackChunkName: "component---src-pages-content-punctuation-mdx" */),
  "component---src-pages-content-voice-and-tone-mdx": () => import("./../../../src/pages/content/voice-and-tone.mdx" /* webpackChunkName: "component---src-pages-content-voice-and-tone-mdx" */),
  "component---src-pages-content-word-list-mdx": () => import("./../../../src/pages/content/word-list.mdx" /* webpackChunkName: "component---src-pages-content-word-list-mdx" */),
  "component---src-pages-design-color-mdx": () => import("./../../../src/pages/design/color.mdx" /* webpackChunkName: "component---src-pages-design-color-mdx" */),
  "component---src-pages-design-icons-chrome-icons-mdx": () => import("./../../../src/pages/design/icons/chrome-icons.mdx" /* webpackChunkName: "component---src-pages-design-icons-chrome-icons-mdx" */),
  "component---src-pages-design-icons-index-mdx": () => import("./../../../src/pages/design/icons/index.mdx" /* webpackChunkName: "component---src-pages-design-icons-index-mdx" */),
  "component---src-pages-design-icons-library-mdx": () => import("./../../../src/pages/design/icons/library.mdx" /* webpackChunkName: "component---src-pages-design-icons-library-mdx" */),
  "component---src-pages-design-index-mdx": () => import("./../../../src/pages/design/index.mdx" /* webpackChunkName: "component---src-pages-design-index-mdx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-patterns-buttons-mdx": () => import("./../../../src/pages/patterns/buttons.mdx" /* webpackChunkName: "component---src-pages-patterns-buttons-mdx" */),
  "component---src-pages-patterns-copy-mdx": () => import("./../../../src/pages/patterns/copy.mdx" /* webpackChunkName: "component---src-pages-patterns-copy-mdx" */),
  "component---src-pages-patterns-drag-and-drop-mdx": () => import("./../../../src/pages/patterns/drag-and-drop.mdx" /* webpackChunkName: "component---src-pages-patterns-drag-and-drop-mdx" */),
  "component---src-pages-patterns-errors-mdx": () => import("./../../../src/pages/patterns/errors.mdx" /* webpackChunkName: "component---src-pages-patterns-errors-mdx" */),
  "component---src-pages-patterns-filters-mdx": () => import("./../../../src/pages/patterns/filters.mdx" /* webpackChunkName: "component---src-pages-patterns-filters-mdx" */),
  "component---src-pages-patterns-index-mdx": () => import("./../../../src/pages/patterns/index.mdx" /* webpackChunkName: "component---src-pages-patterns-index-mdx" */),
  "component---src-pages-patterns-loaders-mdx": () => import("./../../../src/pages/patterns/loaders.mdx" /* webpackChunkName: "component---src-pages-patterns-loaders-mdx" */),
  "component---src-pages-patterns-rich-text-editor-mdx": () => import("./../../../src/pages/patterns/rich-text-editor.mdx" /* webpackChunkName: "component---src-pages-patterns-rich-text-editor-mdx" */),
  "component---src-pages-patterns-save-mdx": () => import("./../../../src/pages/patterns/save.mdx" /* webpackChunkName: "component---src-pages-patterns-save-mdx" */),
  "component---src-pages-patterns-tables-basic-formatting-mdx": () => import("./../../../src/pages/patterns/tables/basic-formatting.mdx" /* webpackChunkName: "component---src-pages-patterns-tables-basic-formatting-mdx" */)
}

